<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav -->
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">Umumiy</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Ilova</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Buyurtma</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Dop soglashenie</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>

              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Bajarilgan ish</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Hisob Faktura</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">To'lovlar</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <div v-if="loading" class="text-primary text-center my-5">
            <b-spinner
              style="width: 3rem; height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </div>
          <!--begin: Wizard Body-->
          <div v-if="!loading" class="row justify-content-center px-8 px-lg-10">
            <div class="col-12">
              <!--begin: Wizard Form-->
              <form class="form" id="kt_form" v-if="list !== undefined">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Shartnoma malumotlari
                  </h4>
                  <div class="row flex-wrap">
                    <div class="form-group col-4 mb-0">
                      <label>Mijoz nomi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.client_name"
                      />
                    </div>
                    <div class="form-group col-4">
                      <div v-if="list.client_inn !== ''">
                        <label>INN</label>
                        <input
                          type="text"
                          disabled
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.client_inn"
                        />
                      </div>
                      <div v-else>
                        <label>INN</label>
                        <input
                          type="text"
                          disabled
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          placeholder="INN KIRITILMAGAN"
                        />
                      </div>
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma turi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.contract_type"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>To'lov turi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.payment_type"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma raqami</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.contract_number"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma bo'lgan sanasi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.start_date | formatDate"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Summa</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.amount"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Foaligi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.is_active_status"
                      />
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    class="
                      mb-10
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <h4 class="font-weight-bold text-dark">
                      Ilovalar ro'yxati
                    </h4>
                    <div class="from-group">
                      <router-link
                        :to="'/contractappenreg/' + this.$route.params.id"
                        class="btn btn-primary font-weight-bolder"
                      >
                        Maxsus ilova +
                      </router-link>
                    </div>
                  </div>
                  <div>
                    <contractappen
                      :id="this.$route.params.id"
                      v-bind:index="returnParamsId"
                    ></contractappen>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div v-if="getZaDetaislById.length == 0">
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Buyurtmalar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Buyurtmalar
                    </h4>
                    <v-data-table
                      :headers="zayavkaList"
                      :items="getZaDetaislById"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.payment_type_name`]="{ item }">
                        <div
                          class="payment__name"
                          :class="item.payment_text_color"
                        >
                          <span
                            :class="[item.payment_color_label]"
                            class="label label-dot"
                          ></span>
                          {{ item.payment_type_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.zayavka_status`]="{ item }">
                        <div class="status__name" :class="item.status_style">
                          {{ item.zayavka_status }}
                        </div>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <ZayavkaAction
                          :index="item"
                          @reload="reloadZayavs"
                        ></ZayavkaAction>
                      </template>
                    </v-data-table>
                    <div class="row justify-content-end mt-2">
                      <div class="mr-4">
                        <p
                          style="font-size: 15px"
                          class="
                            text-dark
                            font-bold
                            label label-lg label-light-success label-inline
                          "
                        >
                          Soni: {{ getZaDetaislById.length }}
                        </p>
                      </div>
                      <div>
                        <p
                          style="font-size: 15px"
                          class="
                            text-dark
                            font-bold
                            label label-lg label-light-success label-inline
                          "
                        >
                          Umumiy Summa:{{ zayavkaTotalSumm }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 4-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div v-if="getCurrAdditionalContracts.length == 0">
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Kelishuv shartnomalar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Kelishuv shartnomalar
                    </h4>
                    <div>
                      <v-data-table
                        :headers="headers"
                        :items="getCorrDetails"
                        :items-per-page="5"
                      ></v-data-table>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 4-->

                <!--begin: Wizard Step 5-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      !loaders.bajarilgan_ish && getCombJobLength.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Bajarilgan ishlar topilmadi
                    </h4>
                  </div>

                  <template v-else-if="loaders.bajarilgan_ish">
                    <div class="p-4 text-center">
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        label="Large Spinner"
                      />
                    </div>
                  </template>

                  <div v-else>
                    <div
                      class="
                        mb-10
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <h4 class="font-weight-bold text-dark">
                        Bajarilgan ishlar
                      </h4>
                      <div>
                        <button
                          @click="getExcel"
                          type="button"
                          :loading="isLoading"
                          :disabled="selected.length == 0 || isLoading"
                          class="btn btn-success mr-2 font-weight-bolder"
                        >
                          Export Excel
                        </button>
                        <button
                          @click="dialog = true"
                          type="button"
                          :loading="isLoading"
                          :disabled="selected.length == 0 || isLoading"
                          class="btn btn-primary font-weight-bolder"
                        >
                          Faktura +
                        </button>
                      </div>
                      <v-dialog v-model="dialog" persistent max-width="600px">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Faktura sanasi</span>
                          </v-card-title>
                          <v-card-text>
                            <v-dialog
                              ref="dialog"
                              :disabled="pending"
                              v-model="modalOperDate"
                              :return-value.sync="oper_date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  :value="oper_date | formatDate"
                                  label="Faktura sanasi"
                                  append-icon="event"
                                  readonly
                                  dense
                                  :disabled="pending"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :disabled="pending"
                                v-model="oper_date"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="error"
                                  @click="modalOperDate = false"
                                >
                                  Bekor qilish
                                </v-btn>
                                <v-btn
                                  small
                                  color="success"
                                  @click="$refs.dialog.save(oper_date)"
                                >
                                  Saqlash
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :disabled="pending"
                              color="error"
                              @click="dialog = false"
                            >
                              Bekor qilish
                            </v-btn>
                            <v-btn
                              :loading="pending"
                              color="success"
                              @click="sendData"
                            >
                              Saqlash
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div class="row">
                      <div class="col-6 pl-0">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="fromDate | formatDate"
                              label="Boshlanishi"
                              append-icon="event"
                              outlined
                              readonly
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @input="fromDate = ''"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fromDate"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div class="col-6 pr-0">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="toDate | formatDate"
                              label="Tugashi"
                              append-icon="event"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @input="toDate = ''"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="toDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                    <div>
                      <v-data-table
                        :loading="isLoading"
                        v-model="selected"
                        :headers="headersJob"
                        :items="getCompJob"
                        :items-per-page="5"
                        show-select
                        class="elevation-1"
                      >
                        <template v-slot:[`item.payment_type_name`]="{ item }">
                          <div :class="item.payment_text_color">
                            <span
                              v-bind:class="[item.payment_color_label]"
                              class="label label-dot"
                            ></span>
                            {{ item.payment_type_name }}
                          </div>
                        </template>

                        <template v-slot:[`item.direction_cities`]="{ item }">
                          <span
                            v-for="(dest, index) in item.direction_cities"
                            :key="dest.id"
                          >
                            {{ dest.city_name.name }}
                            <span
                              v-if="index == item.direction_cities.length - 1"
                            ></span>
                            <span v-else>-</span>
                          </span>
                        </template>

                        <template v-slot:[`item.start_date`]="{ item }">
                          {{ item.start_date && item.start_date | formatDate }}
                        </template>

                        <template v-slot:[`item.price`]="{ item }">
                          {{ item.price && item.price.toLocaleString('zh-ZH') }}
                        </template>

                        <template v-slot:[`item.nds_summa`]="{ item }">
                          {{
                            item.nds_summa &&
                              item.nds_summa.toLocaleString('zh-ZH')
                          }}
                        </template>

                        <template v-slot:[`item.price_with_nds`]="{ item }">
                          {{
                            item.price_with_nds &&
                              item.price_with_nds.toLocaleString('zh-ZH')
                          }}
                        </template>

                        <template v-slot:[`item.end_date`]="{ item }">
                          {{ item.end_date && item.end_date | formatDate }}
                        </template>
                      </v-data-table>
                      <div class="row justify-content-end mt-2">
                        <div class="mr-4">
                          <p
                            style="font-size: 15px"
                            class="
                              text-dark
                              font-bold
                              label label-lg label-light-success label-inline
                            "
                          >
                            Tanlanganlar jami soni:
                            {{ selectedSumCountObj.count }}
                          </p>
                        </div>
                        <div style="margin-right: 2rem">
                          <p
                            style="font-size: 15px"
                            class="
                              text-dark
                              font-bold
                              label label-lg label-light-success label-inline
                            "
                          >
                            Tanlanganlar jami summa:
                            {{ selectedSumCountObj.sum }}
                          </p>
                        </div>

                        <div class="mr-4">
                          <p
                            style="font-size: 15px"
                            class="
                              text-dark
                              font-bold
                              label label-lg label-light-success label-inline
                            "
                          >
                            Jami soni: {{ getCompJob.length }}
                          </p>
                        </div>
                        <div>
                          <p
                            style="font-size: 15px"
                            class="
                              text-dark
                              font-bold
                              label label-lg label-light-success label-inline
                            "
                          >
                            Jami Summa:{{ compJobTotal }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 5-->

                <!--begin: Wizard Step 6-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      !loaders.hisob_faktura && factureBycontract.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Hisob fakturalar topilmadi
                    </h4>
                    <v-data-table
                      :headers="headers222"
                      :items="factureBycontract"
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <FacturaAction
                          :index="item"
                          @deleteFaktura="deleteFaktura"
                        />
                      </template>

                      <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.price !== null">
                          {{
                            item.get_factura_amount.price.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.nds`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.nds !== null">
                          {{
                            item.get_factura_amount.nds.toLocaleString('zh-ZH')
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price_with_nds`]="{
                          item
                        }"
                      >
                        <div
                          v-if="item.get_factura_amount.price_with_nds !== null"
                        >
                          {{
                            item.get_factura_amount.price_with_nds.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <!-- sanadan sanagacha -->

                      <template v-slot:body.append>
                        <tr>
                          <td><b>Jami:</b></td>
                          <td>
                            <b>{{ sumColumn('price') }}</b>
                          </td>
                          <td><b>Jami(nds bilan):</b></td>
                          <td>
                            <b>{{ sumColumn('price_with_nds') }}</b>
                          </td>
                          <td>
                            <v-text-field
                              v-model="fromDate"
                              type="date"
                              label="sanadan"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="toDate"
                              type="date"
                              label="sanagacha"
                            ></v-text-field>
                          </td>
                          <td colspan="2"></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>

                  <template v-else-if="loaders.hisob_faktura">
                    <div class="p-4 text-center">
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        label="Large Spinner"
                      />
                    </div>
                  </template>

                  <div v-else>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Hisob fakturalar
                    </h4>
                    <v-data-table
                      :headers="headers222"
                      :items="factureBycontract"
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <FacturaAction
                          :index="item"
                          @deleteFaktura="deleteFaktura"
                        />
                      </template>

                      <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.price !== null">
                          {{
                            item.get_factura_amount.price.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.nds`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.nds !== null">
                          {{
                            item.get_factura_amount.nds.toLocaleString('zh-ZH')
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price_with_nds`]="{
                          item
                        }"
                      >
                        <div
                          v-if="item.get_factura_amount.price_with_nds !== null"
                        >
                          {{
                            item.get_factura_amount.price_with_nds.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <!-- sanadan sanagacha -->

                      <template v-slot:body.append>
                        <tr>
                          <td><b>Jami:</b></td>
                          <td>
                            <b>{{ sumColumn('price') }}</b>
                          </td>
                          <td><b>Jami(nds bilan):</b></td>
                          <td>
                            <b>{{ sumColumn('price_with_nds') }}</b>
                          </td>
                          <td>
                            <v-text-field
                              v-model="fromDate"
                              type="date"
                              label="sanadan"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="toDate"
                              type="date"
                              label="sanagacha"
                            ></v-text-field>
                          </td>
                          <td colspan="2"></td>
                        </tr>
                      </template>
                    </v-data-table>

                    <div class="row justify-content-end">
                      <div>
                        <p
                          style="font-size: 15px"
                          class="
                            text-dark
                            font-bold
                            label label-lg label-light-success label-inline
                          "
                        >
                          Soni: {{ factureBycontract.length }}
                        </p>
                      </div>
                      <div class="ml-5">
                        <p
                          style="font-size: 15px"
                          class="
                            text-dark
                            font-bold
                            label label-lg label-light-success label-inline
                          "
                        >
                          Summasi:
                          {{ facturaTotalSumm.toLocaleString('zh-ZH') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 6-->
                <!--begin: Wizard Step 7-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div>
                    <Payments />
                  </div>
                </div>
                <!--end: Wizard Step 7-->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import contractappen from './contractappen'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ZayavkaAction from '@/view/content/dropdown/ZayavkaAction.vue'
import FacturaAction from '@/view/content/dropdown/FacturaAction.vue'
import Payments from './components/Payments'
import KTWizard from '@/assets/js/components/wizard'
import axios from 'axios'

export default {
  name: 'ContractDetails',
  data() {
    return {
      modalOperDate: false,
      oper_date: '',
      dialog: false,
      mainLoader: false,
      range: {},
      list: {},
      loading: true,
      toDate: '',
      fromDate: '',
      menu2: false,
      menu: false,
      name: '',
      selected: [],
      tab: null,
      msg: 'Shartnomalar malumotari',
      selectedID: '',
      fields: [
        {
          label: 'Mijoz nomi',
          key: 'zayavka_owner'
        },
        {
          label: 'INN',
          key: 'client_inn'
        },
        {
          label: 'Turi',
          key: 'contract_type'
        },
        {
          label: 'Shartnoma raqami',
          key: 'contract_number'
        },
        {
          label: 'Yaratilgan sana',
          key: 'start_date'
        },
        {
          label: 'Tugash sanasi',
          key: 'end_date'
        }
      ],
      headers222: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Mijoz',
          value: 'client_name'
        },
        {
          text: 'Raqami',
          value: 'reg_number'
        },
        {
          text: 'Shartnoma',
          value: 'contract_reg_number'
        },
        {
          text: 'Narxi',
          value: 'get_factura_amount.price'
        },
        {
          text: 'Nds',
          value: 'get_factura_amount.nds'
        },
        {
          text: 'narxi(nds)',
          value: 'get_factura_amount.price_with_nds'
        },
        {
          text: 'Soni',
          value: 'get_factura_amount.count'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        { text: '', value: 'action', sortable: false }
      ],
      headersJob: [
        { text: 'Id', value: 'id' },
        { text: "Yo'nalishi", value: 'direction_cities' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Boshlanish', value: 'start_date' },
        { text: 'Tugash', value: 'end_date' },
        { text: 'Summa', value: 'price' },
        { text: 'Nds Summa', value: 'nds_summa' },
        { text: 'Summa(Nds)', value: 'price_with_nds' }
      ],
      zayavkaTotalSumm: 0,
      compJobTotal: 0,
      pending: false,

      loaders: {
        bajarilgan_ish: false,
        hisob_faktura: false
      }
    }
  },
  async created() {
    this.loaders.bajarilgan_ish = true
    this.$store.dispatch('getClientContract', this.$route.params.id)
    this.$store.dispatch('getAllAdditionalContracts', this.$route.params.id)
    this.$store.dispatch('getZaById', this.$route.params.id)
    this.$store.dispatch('getAllTruckTypes')
    await this.$store
      .dispatch('getCurrCompJob', this.$route.params.id)
      .then(() => (this.loaders.bajarilgan_ish = false))
      .catch(() => {
        this.loaders.bajarilgan_ish = false
      })
    await this.$store
      .dispatch('factureBycontract', this.$route.params.id)
      .then(() => (this.loaders.hisob_faktura = false))
      .catch(() => {
        this.loaders.hisob_faktura = false
      })
    this.$store.dispatch('contractPayments', this.$route.params.id)
    this.$store.dispatch('getNds')
  },
  watch: {
    getCurrContractDetails: function(val) {
      this.list = val
      this.loading = false
    },
    getZaDetaislById: function(val) {
      this.zayavkaTotalSumm = 0
      let list = val.map(x => {
        if (Boolean(x.price_with_nds) && typeof x.price_with_nds == 'string') {
          return x.price_with_nds.replace(/[ ,]/g, '')
        } else return 0
      })
      if (list.length) {
        list.forEach(x => {
          this.zayavkaTotalSumm = this.zayavkaTotalSumm + parseFloat(x)
        })
        // list = list.reduce((a, b) => a + b)
      }
      this.zayavkaTotalSumm = this.zayavkaTotalSumm.toLocaleString('es-US')
    },
    getCompJob(val) {
      this.compJobTotal = 0
      let list = val.map(x => x.price_with_nds)
      if (list.length) {
        list.forEach(x => {
          this.compJobTotal = this.compJobTotal + parseFloat(x)
        })
      }
      this.compJobTotal = this.compJobTotal.toLocaleString('es-US')
    },
    loading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  computed: {
    selectedSumCountObj() {
      let count = this.selected.length
      console.log(this.selected)
      let sum = 0
      this.selected.forEach(sel => {
        sum += sel.price_with_nds
      })
      sum = sum.toLocaleString('es-US')
      return { count, sum }
    },
    getCurrContractDetails() {
      const data = this.$store.state.requests.allContracts
      if (data.amount !== undefined && data.amount !== null) {
        data.amount = data.amount.toLocaleString('es-US')
      }
      return this.$store.state.requests.allContracts
    },
    getCurrAdditionalContracts() {
      return this.$store.state.requests.allclientcontracts
    },
    factureBycontract() {
      return (
        this.$store.getters.GetfactureBycontract.filter(value => {
          if (this.fromDate && !this.toDate) {
            return value.oper_date >= this.fromDate
          } else if (!this.fromDate && this.toDate) {
            return value.oper_date <= this.toDate
          } else if (this.fromDate && this.toDate) {
            return (
              value.oper_date >= this.fromDate && value.oper_date <= this.toDate
            )
          } else {
            return value
          }
        }) || []
      )
    },
    facturaTotalSumm() {
      let data = this.$store.state.requests.allFacturasByContract
      data = data
        .filter(x => x.get_factura_amount.price_with_nds)
        .map(x => x.get_factura_amount.price_with_nds)
        .reduce((a, b) => a + b, 0)
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getZaDetaislById() {
      const data = this.$store.state.requests.setZayavById
      data.forEach(x => {
        x.price_with_nds = x.price_with_nds.toLocaleString('es-US')
      })
      return data
    },
    gettruck() {
      return this.$store.state.requests.allTruckTypes
    },
    returnSelectedId() {
      return this.selectedID
    },
    returnParamsId() {
      return this.$route.params.id
    },
    getNameCon() {
      return this.$store.state.requests.nameOfContracts
    },
    getCorrDetails() {
      this.$store.state.requests.allclientcontracts.forEach(element => {
        if (element.contract_type == 'MU') {
          element.contract_type = 'Muddatli'
        } else if (element.contract_type == 'SU') {
          element.contract_type = 'Summa'
        } else {
          element.contract_type = 'Muddatsiz'
        }
        if (element.is_active == true) {
          element.is_active = 'Faol'
        } else {
          element.is_active = 'Nofaol'
        }
      })
      return this.$store.state.requests.allclientcontracts
    },
    getCompJob() {
      return (this.$store.state.requests.currCompJob || []).filter(x => {
        const startDate = this.$dayjs(x.start_date)
        const fromDate = this.$dayjs(this.fromDate)
        const toDate = this.$dayjs(this.toDate)

        if (this.fromDate && this.toDate) {
          return startDate >= fromDate && startDate <= toDate
        } else if (this.fromDate) {
          return startDate >= fromDate
        } else if (this.toDate) {
          return startDate <= toDate
        }

        return x
      })
    },

    // cGetCompJob() {
    //   return this.getCompJob.length
    //     ? this.getCompJob
    //     : this.$store.state.requests.currCompJob
    // },

    getCombJobLength() {
      let data = this.$store.state.requests.currCompJob
      return data
    },
    headers() {
      return [
        { text: 'Id', value: 'id' },
        { text: 'Mijoz nomi', value: 'client_name' },
        { text: 'Inn', value: 'client_inn' },
        { text: 'Turi', value: 'contract_type' },
        { text: 'Reg raqami', value: 'contract_number' },
        { text: 'Boshlanish', value: 'start_date' },
        { text: 'Tugash', value: 'end_date' },
        { text: 'Summa', value: 'amount' },
        { text: 'Faol', value: 'is_active' }
      ]
    },
    zayavkaList() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Buyurtma ownrer', value: 'zayavka_owner' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Narxi', value: 'price_with_nds' },
        // { text: 'Kg', value: 'post_kg' },
        // { text: 'm3', value: 'post_m3' },
        // { text: "Toʻlov turi", value: "payment_type_name" },
        // { text: 'Status', value: 'zayavka_status' },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  filters: {
    formatDate(val) {
      return (
        val &&
        val
          .split('-')
          .reverse()
          .join('-')
      )
    }
  },
  methods: {
    sumColumn(key) {
      let total = this.factureBycontract.reduce(
        (a, b) => a + (b.get_factura_amount[key] || 0),
        0
      )
      return total.toLocaleString('es-US')
    },
    deleteFaktura(id) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish',
        showLoaderOnConfirm: true,
        preConfirm: () =>
          new Promise(async res => {
            await this.$store.dispatch('deleteFaktura', id)

            this.loaders.bajarilgan_ish = true
            await this.$store.dispatch('getCurrCompJob', this.$route.params.id)

            await this.$store
              .dispatch('factureBycontract', this.$route.params.id)
              .catch(() => {
                this.loaders.bajarilgan_ish = false
              })

            this.loaders.bajarilgan_ish = false

            res()
          })
      }).then(result => {
        if (result.isConfirmed) {
          this.selected = []
          Swal.fire('Ochirildi!', '', 'success')
        }
      })
    },
    initWizard() {
      this.$nextTick(() => {
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })
        // Validation before going to next page
        wizard.on('beforeNext', function(/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    },
    reloadZayavs() {
      this.$store.dispatch('getZaById', this.$route.params.id)
    },
    getExcel() {
      const data = {
        zayavkas_ids: [...this.selected.map(x => x.id)]
      }
      axios({
        method: 'post',
        url: 'contracts/clientcontracts/completed-jobs/exporttoexcel/',
        responseType: 'blob',
        data: data
      }).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Completed Job')
        document.body.appendChild(link)
        link.click()
      })
    },
    tabNum(value) {
      if (value == '5') {
        this.$store.dispatch('factureBycontract', this.$route.params.id)
      }
    },
    async sendData() {
      if (this.selected.length !== 0) {
        if (
          this.selected.find(
            x => x.start_date.substr(0, 10) > this.oper_date
          ) !== undefined
        ) {
          Swal.fire({
            title: '',
            text: 'Sanada muammo bor!',
            icon: 'error'
          })
        } else {
          const data = {
            oper_date: this.oper_date,
            contract: this.$route.params.id,
            zayavkas: this.selected.map(x => x.id)
          }

          this.pending = true
          await this.$store
            .dispatch('contractFactures', data)
            .catch(() => (this.pending = false))
          await this.$store.dispatch('factureBycontract', this.$route.params.id)
          this.pending = false
          this.dialog = false
          this.selected = []
        }
      }
    },
    selectedTruck(payload) {
      this.selectedID = payload
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Shartnoma malumotlari' }])
    // Initialize form wizard
    this.initWizard()
  },

  components: {
    contractappen,
    Payments,
    FacturaAction,
    ZayavkaAction
  }
}
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.overlay_style {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background-color: #333;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: inherit;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.wizard-step {
  padding: 0;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
